<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=1 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">企业认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="form" :label-position="labelPosition" v-loading="loading" :rules="rules"
                    label-width="100px" class="elform" ref="elForm">

                    <el-form-item class="formL small" label="最高权力人手机号" prop="superUserMobile">
                      <el-input v-model="form.superUserMobile"></el-input>
                    </el-form-item>
                    <el-form-item class="formL small" v-show="form.realName!=''">
                      <span v-html='form.realName'></span>
                    </el-form-item>
                    <el-form-item class="formL small" label="最高权力人职务" prop="superUserPost">
                      <el-input v-model="form.superUserPost"></el-input>
                    </el-form-item>

                    <el-form-item label="股东会授权书" prop="superUserAuthCert">
                      <el-upload style="float:left;" :multiple="false" class="upload-demo" :headers="myHeaders"
                        list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg"
                        :file-list="fileList" :on-success="busSuccess" :on-error="busError" :on-change="busChange">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">提交</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import { cityjson } from '@/utils/cityjson.js';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
import { getuserinfobymobile, companysuperuserinfo } from '@/api/user';

import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          self.onblur();
          return callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      province: [],
      city: cityjson,
      isbranch: false,
      loading: false,
      dialogImageUrl: '',
      businessend: '',
      isshowbusinessend: false,
      dialogVisible: false,
      dialogimgVisible: false,
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      entregtypedata: [],
      entregcategorydata: [],
      entregstatusdata: [],
      fileList: [],
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      form: {
        superUserPost: '总经理',
        superUserId: 0,
        superUserAuthCert: '',
        superUserMobile: '',
        realName: '',
        id:0
      },
      rules: {
        superUserPost: [{ required: true, message: '请输入最高权力人职务' }],
        superUserMobile: [
          { required: true, validator: checkPhone, trigger: 'blur' },
        ],
        superUserAuthCert: [{ required: true, message: '请上传股东会授权书' }],
      },
    };
  },
  methods: {
    onblur() {
      let params = {
        userMobile: this.form.superUserMobile,
        isAuth: 1,
      };
      getuserinfobymobile(params).then((res) => {
        if (res.data.code === 1) {
          this.form.realName = '姓名：' + res.data.result.realName;
          this.form.superUserId = res.data.result.id;
        } else {
          this.form.realName =
            '<span style="color:red">该手机号码未在本平台注册或者未进行实名认证</span>';
        }
      });
    },
    onsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let params = {
          //   superUserId: 0,
          //   superUserPost: '',
          //   superUserAuthCert: '',
          //   id:0
          // };
          companysuperuserinfo(this.form).then((res) => {
            if (res.data.code === 1) {
              this.$message({
                message: '恭喜你，提交成功',
                type: 'success',
              });
              setTimeout(function () {
                window.location.href = '/user/enterpriselist';
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log('提交失败!');
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      this.fileList = fileList;
      if (response.code == 1) {
        this.form.superUserAuthCert = response.result.fileToken;
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    locatefal() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
  created() {
     this.form.id = this.$route.query.id;
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
/* .accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
} */
.accountset .info-cnt >>> .el-form-item__content button.showbusinessend {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}
.accountset .account-nav li.cur i.detail {
  background-position: 0 -142px;
  background-image: url('../../assets/img/account-ico.png');
}
.amapstyle {
  height: 500px;
}
.accountset >>> .el-dialog__wrapper .amap-page-container .address {
  font-size: 14px;
  font-weight: 700;
  position: relative;
  top: -23px;
  width: 80%;
}
</style>
